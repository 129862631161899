

































import { alternateClassMixin, contentfulHtmlMixin, sluglMixin } from '@/mixins';
import moment from 'moment';

export default {
  filters: {
    formatDate: function(value:string):string {
      return moment(value).format('DD-MM-YYYY hh:mm');
    }
  },
  props: {
    data: Object,
    index: Number,
    facebook: Object,
  },
  mixins: [alternateClassMixin, contentfulHtmlMixin, sluglMixin],
  methods: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    chunked(data:Array<any>): Array<any> {
      const chunks = 3;
      let i = 0;
      let j = 0;
      const result = [];
      let subObj;
      for (let x=0; x < data.length; x++) {
        if (x % chunks == 0) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          subObj = { id:i++, data:Array<any>() };
          result.push(subObj);
        }
        if (subObj) subObj.data.push({ ...data[x], id:j++ });
      }

      return result;
    }
  },
};
