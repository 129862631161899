export const cmsConfig = {
	cmsUrl: '/contentful',
};

export const apiConfig = {
	host: 'https://bendcoaching.azurewebsites.net',
	heartbeatPath: 'heartbeat',
	sendmailPath: 'sendmail',
}

export const recaptchaConfig = {
	htmlKey: '6Lcsx-AeAAAAAEMdkPZ4yrPeIpOqfH92vDdnn63H',
	api: 'https://www.google.com/recaptcha/api.js?render=',
}

export const facebookConfig = {
	enabled: false,
	api : 'https://test.bendcoaching.nl/api/facebook',
}
