






















import Nav from "./components/Nav.vue";
import NavToggle from "./components/NavToggle.vue";
import Home from "./components/Sections/Home.vue";
import PlainSection from "./components/Sections/PlainSection.vue";
import Facebook from "./components/Sections/Facebook.vue";
import Items from "./components/Sections/Items.vue";
import Contact from "./components/Sections/Contact.vue";
import Footer2 from "./components/Sections/Footer2.vue";
import { recaptchaConfig } from '@/config';
import store from './store';
import { loadScript } from '@/utils'
import Vue from 'vue';

export default {
  computed: {
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    vuexData() {
      return store.state.data;
    },
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    tempComponents() {
      return [
        //{"type": "resume"},
        //{"type": "skills"},
        //{"type": "portfolio"},
        //{"type": "facts"},
        //{"type": "testimonials"},
        { type: "blog" },
        { type: "contact" },
        { type: "footer-1" },
        { type: "scroll-up" },
      ];
    },
  },
  mounted():void {
    // These scripts must be loaded after DOM is ready.
    Vue.nextTick(() => {
      // Addo scripts
      loadScript('/js/main.js');
      loadScript('/js/animated.headline.js');

      // Loading the recaptcha main script
      loadScript(`${recaptchaConfig.api}${recaptchaConfig.htmlKey}`);
    });
  },
  components: {
    Nav,
    NavToggle,
    Home,
    PlainSection,
    Items,
    Facebook,
    Contact,
    Footer2,
  },
};
