




import { contentfulHtmlMixin, sluglMixin } from '@/mixins';

export default {
  mixins: [contentfulHtmlMixin, sluglMixin],
  props: {
    data: Object,
  },
}
