










































import { contentfulHtmlMixin } from '@/mixins';
export default {
  mixins: [contentfulHtmlMixin],
  props: {
    data: Object,
  },
};
