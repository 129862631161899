import slugit from 'slugify';

export const sluglMixin = {
  methods: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    slug: (from: string): string => {
      return slugit(from).toLowerCase();
    }
  }
}
