import Vue from 'vue'
import Vuex from 'vuex'
import App from './App.vue'
import store from '@/store';
import { contentfulDataFetch, facebookDataFetch, loadScript } from '@/utils'
import axios from 'axios';
import { apiConfig } from './config';

Vue.use(Vuex);

Vue.config.productionTip = false

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const window: any;

store.commit('set', { ...window.content, heartbeat: { success:false } });
new Vue({
  store,
  render: h => h(App),
}).$mount('#addo-page');

// Get Contentful cms data
contentfulDataFetch().then(res => {
  if (res.seo) {
    store.commit('set', { ...store.state.data, ...res });
  }
});

// Get Facebook entries
facebookDataFetch().then(res => {
  store.commit('set', { ...store.state.data, facebook:{ data:res.data } });
}).then(() => {
  loadScript('/js/main.js');
});

setTimeout(() => {
  // Again, load main.js after 2sec because burger menu issues
  loadScript('/js/main.js');
}, 2000);

// Fire heart beat once on page load
axios.get(`${apiConfig.host}/${apiConfig.heartbeatPath}`).then(response => {
  store.commit('set', { ...store.state.data, heartbeat: { 
    success: response.data.success,
    error: response.data.stamp,
  }});
}).catch((err) => {
  store.commit('set', { ...store.state.data, heartbeat: { 
    success: false, 
    error: err,
  }});
}).finally(() => {
  // And one more time after 10 minutes
  setTimeout(() => {
    axios.get(`${apiConfig.host}/${apiConfig.heartbeatPath}`);
  }, 600000);
});
