














import { contentfulHtmlMixin, sluglMixin } from '@/mixins';

export default {
  mixins: [contentfulHtmlMixin, sluglMixin],
  props: {
    data: Object,
  },
  methods: {
    getId: (id: string|undefined, sectionType: string): string => id ? `#${id}` : `#${sectionType.toLowerCase()}`,
  },
};
