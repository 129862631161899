import axios from 'axios';
import { facebookConfig } from '@/config';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const asyncGet = (): Promise<any> => {
    return new Promise(resolve => {
        axios.get(facebookConfig.api)
            .then(response => resolve(response.data))
            .catch(error => resolve(error))
    });
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const facebookDataFetch = async (): Promise<any> => {
    if (facebookConfig.enabled) {
        const response = await asyncGet();
        return response;
    } else {
        return { data: undefined };
    }
}
