




































































































import { alternateClassMixin, contentfulHtmlMixin, sluglMixin } from '@/mixins';
import { sendMail } from '@/utils';
import { recaptchaConfig } from '@/config';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const window: any;

export default {
  props: {
    data: Object,
    index: Number,
    heartbeat: Object,
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data():any {
    return {
      name: '',
      email: '',
      phone: '',
      subject: '',
      message: '',
      newForm: true,
    }
  },
  mixins: [alternateClassMixin, contentfulHtmlMixin, sluglMixin],
  methods: {
    // Cannot be an arrow-function because of this-usage
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    sendForm: function (evt:any|undefined): void {
      evt.preventDefault();

      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const vm:any = this;

      // Polling until grecaptcha is available
      const myPoll = setInterval(() => {
        console.log('poll');
        if (window.grecaptcha && window.grecaptcha.execute) {
          clearInterval(myPoll);
          window.grecaptcha.execute(recaptchaConfig.htmlKey, {action: 'submit'}).then((token:string) => {
            sendMail({
              name: vm.name,
              email: vm.email,
              phone: vm.phone,
              subject: vm.subject,
              message: vm.message,
              captcha: token,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            }, (response:any) => {
              if (response.data.success) {
                vm.newForm = false;
              } else {
                alert('Er is een technische fout opgetreden. Probeert u het aub nogmaals.');
              }
            });
          });
        }
      }, 100);
    },
    
    resetForm: function(): void {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const vm:any = this;
      vm.subject = '';
      vm.message = '';
      vm.newForm = true;
    },
  },
};
