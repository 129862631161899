import axios from 'axios';
import { apiConfig } from '@/config';
import { MailType } from 'types/mailType';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const window: any;

export const sendMail = async (mail:MailType, func:any): Promise<void> => {
    const host = window.apiHost ? window.apiHost : apiConfig.host;
    console.log('Using host: ', host)
    const url = `${host}/${apiConfig.sendmailPath}`;

    await axios.post(url, mail).then(response => {
        func(response);
    });
}
