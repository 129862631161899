import axios from 'axios';
import resolveResponse from 'contentful-resolve-response'
import { cmsConfig } from '@/config';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const window: any;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const asyncGet = (): Promise<any> => {
    const cmsUrl = window.cmsUrl ? window.cmsUrl : cmsConfig.cmsUrl;

    return new Promise(resolve => {
        axios.get(cmsUrl)
            .then(response => resolve(resolveResponse(response.data)[0].fields))
            .catch(error => resolve(error))
    });
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const contentfulDataFetch = async (): Promise<any> => {
    const response = await asyncGet();
    return response;
}
