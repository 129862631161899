

























































import { alternateClassMixin, contentfulHtmlMixin, sluglMixin } from '@/mixins';
export default {
  mixins: [alternateClassMixin, contentfulHtmlMixin, sluglMixin],
  props: {
    data: Object,
    index: Number,
  },
};
